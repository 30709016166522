import LOGO from 'assets/img/brand/LogoCut.png'


let keys = {
    TIMEZONE: 'America/Los_Angeles',
    COMPANY: 'Fairmax Law',
    LOGO
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'auth.myfairmax.com') {

    keys = {
        ...keys,
        API_URL               : 'https://api.myfairmax.com',
        SYSTEM_API_KEY        : 'architeck-3876143876',
        ZEAMSTER_ORIGIN       : 'https://api.fortispay.com',
        CLIENT_URL            : 'https://clients.myfairmax.com',
        APP_URL               : 'https://app.myfairmax.com',
        TALK_URL               : 'https://talk.myfairmax.com',
        ADMIN_URL             : 'https://admin.myfairmax.com',
        MICROSOFT_CLIENT_ID   : 'ec7d7d85-1794-4493-87ed-d3c0c71a7b3d',
        env                   : 'prod',
        ZAP_REPORTS_URL       : 'https://client.zapreports.com',

    }

//using staging keys
} else if(HOSTNAME === 'staging.auth.myfairmax.com') {

    console.log('s')

    keys = {
        ...keys,
        API_URL               : 'https://staging.api.myfairmax.com',
        SYSTEM_API_KEY        : 'architeck-4874517265',
        ZEAMSTER_ORIGIN       : 'https://api.sandbox.zeamster.com',
        CLIENT_URL            : 'https://staging.clients.myfairmax.com',
        APP_URL               : 'https://staging.app.myfairmax.com',
        TALK_URL               : 'https://staging.talk.myfairmax.com',
        ADMIN_URL             : 'https://staging.admin.myfairmax.com',
        MICROSOFT_CLIENT_ID   : 'be6a6e5a-451c-4e19-98e9-ae0621408aba',
        env                   : 'staging',
        ZAP_REPORTS_URL       : 'https://staging.client.zapreports.com',

    }

//using development keys
} else {

    console.log('d')

    // wrapped in try catch as this will fail in staging and production
    // since this file does not exist in version control. Only used for dev purposes
    try {
       
        const devKeys = require('./keysDev')

        keys = {
            ...keys,
            ...devKeys,
            env: 'dev',

        }

   } catch(e) {}

}

export default keys
